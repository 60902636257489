@use "../../theme";

.update-user-container {
    width: 80%;
    height: 80%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);
    overflow-x: hidden;
    overflow-y: scroll;

    h3 {
        width: 70%;
        //height: 10%;
        color: theme.$base-color;
        margin-bottom: 30px;
        margin-top: 30px;

    }

    .update-user-div {
        width: 70%;
        // height: 10%;
        margin: 7px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .update-user-txtbox {
            height: 10%;
            width: 60%;
        }

        label {
            width: 30%;
            display: block;
        }
    }

    .update-user-password {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5%;
        position: relative;


        label {
            width: 30%;
        }

        .create-pwd {
            //width: 70%;
            display: flex;
            z-index: 10;
            position: absolute;
            left: 100%;
        }

        span {
            color: theme.$error-color;
        }
    }

    .update-user-btn {
        width: 590px;
        display: flex;
        justify-content: end;
        margin-top: 3%;

        .--savebtn {
            width: 79px;
        }

    }

}

@media(max-resolution:150dpi) {
    .update-user-container {
        height: 90%;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }

        .update-user-div {
            width: 70%;
            // height: 10%;
            margin: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .create-user-txtbox {
                height: 10%;
                width: 60%;
            }
        }

    }
}

@media(max-resolution:125dpi) {
    .update-user-container {
        height: 88%;
        width: 90%;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 20px;
            margin-top: 20px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }

        .update-user-div {
            width: 40%;
            // height: 10%;
            margin: 13px;
            margin-right: 19%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .update-user-txtbox {
                height: 10%;
                width: 60%;
            }
        }

    }
}