@use "../../theme";

.create-Notify-container {
    width: 90%;
    height: 90%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        color: theme.$base-color;
        width: 70%;
        margin-bottom: 25px;
    }

    .create-Notify-div {
        display: flex;
        flex-direction: row;
        width: 70%;
        margin: 15px;
        justify-content: space-between;

        .search-div {
            width: 80%
        }

        input {
            border: none;
            // border-radius: 4px;
            width: 60%;
            padding: 5px;
            outline: none !important;

        }

        .label1 {
            display: block;
            // margin-bottom: 10px;
            // color: theme.$header-color;
            width: 30%;
        }

        .Notify-div {
            display: flex;
            flex-direction: row;
            width: 80%;
            justify-content: space-between;
            flex-wrap: wrap;

            div {
                display: flex;
                width: 50%;
                align-items: center;

                .container {
                    display: block;
                    position: relative;
                    padding-left: 25px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    font-family: open sans;
                    width: 80%;
                }

                /* Hide the browser's default checkbox */
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;

                }

                /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    height: 16px;
                    width: 16px;
                    border: 1px solid black;

                }

                //  On mouse-over, add a grey background color
                .container input~.checkmark {
                    background-color: white;
                }

                /* When the checkbox is checked, add a blue background */
                .container input:checked~.checkmark {
                    background-color: rgba(38, 137, 13, 1);
                    border: none;

                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked~.checkmark:after {

                    display: block;


                }

                /* Style the checkmark/indicator */
                .container .checkmark:after {
                    left: 5px;
                    top: 2px;
                    width: 3px;
                    height: 7px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);

                }




            }
        }
    }
}

.create-Notify-btn {
    //margin-left: 100px;
    width: 60%;
    display: flex;
    justify-content: end;
    margin-top: 2%;
}

.notification-list {
    display: flex;
    flex-direction: column;
    width: 50%;

    div {
        //width: 600px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        div {
            display: flex;
            margin-left: 30px;

            input {
                margin-right: 10px;
            }
        }
    }
}