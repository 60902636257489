@use "../../theme";

.coachmark-main {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 11;

    .--block {
        z-index: 10;
        box-shadow: 0 0 0 99999px rgb(0 0 0 / 70%);
        position: fixed;
        user-select: none;
    }

    .--coachmark-info {
        z-index: 10;
        background: theme.$white-color;
        position: fixed;
        width: 500px;
        min-height: 150px;

        img {
            position: absolute;

            &.--rotateleft {
                transform: rotate(0deg);
                left: -10px;
                top: 60px;
                right: auto;
            }

            &.--rotateright {
                transform: rotate(180deg);
                right: -10px;
                left: auto;
                top: 60px;

            }

            &.--rotatedown {
                transform: rotate(-90deg);
                bottom: -15px;
                left: 225px;
                right: auto;
            }

            &.--rotatetop {
                top: -15px;
                left: 225px;
                right: auto;
                transform: rotate(90deg);
            }

        }

        .--content {
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 145px;

            .--closesvg {
                top: 10px;
                right: 10px;
                left: auto;
                cursor: pointer;
            }


            .--content-body {
                flex-grow: 1;
                display: flex;
                align-items: center;
                padding: 20px;
                overflow-y: auto;
            }

            .--content-footer {
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                padding: 5px;

                .--coachbtn-close {
                    background: #53565A;
                    margin: 0px 10px;
                }
            }
        }
    }
}