@use "../../theme";


.tootip-name {
    display: flex;
    align-items: center;

    .password-tooltip {
        background-color: black;
        color: theme.$white-color;
        text-align: left;
        padding: 6px;
    }

}