@use "../../theme";


.--tabbed-nav-bar {
    width: 72%;
    min-height: 70px;
    padding: 40px 0 15px 0;
    flex-basis: 85px;
    top: 80px;
    position: absolute;
    margin-left: 5px;

    .--tabbed-nav {
        float: left;

        .nav-tab-item {
            padding: 4px 10px;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-image: linear-gradient(to top, #777, #7770) 100% 5 repeat;
            font-size: 15px;
            user-select: none;
            cursor: pointer;
            font-family: Open Sans;
            font-weight: 400;
            line-height: 20px;

        }

        .active-nav-tab {
            color: theme.$base-color;
            font-weight: 600;
            border-bottom-color: theme.$base-color;
            border-image: none;
        }

        // .-disable {
        //     opacity: 0.3;
        //     pointer-events: none;
        // }
    }
}