@use "../../theme";

.table-for-api-container {
    width: 90%;
    height: 90%;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);
    padding: 20px 36px;

    .table-for-api-heading {
        display: flex;
        justify-content: space-between;
        padding: 8px 0px;
        // box-shadow: inset 0px -1px 0px theme.$border-color;

        h3 {
            color: theme.$base-color;
        }
    }

    .api-table-body {
        height: 80%;
        padding: 24px 0px;

        .table-for-api-header {
            padding: 20px 0px;
            box-shadow: inset 0px -1px 0px theme.$border-color;

            .table-for-api-content {
                padding-left: 36px;
            }
        }

        .api-main-data {
            height: 96%;
            overflow-y: auto;

            .table-for-api-content {

                .api-table-item {
                    padding: 14px 0px 14px 36px;
                    box-shadow: inset 0px -1px 0px theme.$border-color;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    &.--notdone {
                        color: theme.$error-color;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }


            }
        }

    }

}