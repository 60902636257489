@use "../../theme";

.toast-container {
    position: fixed;
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    z-index: 11;


    .--toast {
        box-sizing: border-box;
        color: white;
        padding: 8px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 580px;
        width: 580px;
        max-width: 580px;

        &.--success {
            background: theme.$base-color;
        }

        &.--error {
            background: theme.$error-color;
        }

        &.--warning {
            background: theme.$warning-color;
            color: theme.$black-color;
        }

        &.--info {
            background: #007CB0;
            color: theme.$white-color;
        }

        .--spanblock {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                padding: 0px 5px;
                align-self: center;
                display: block;
                width: 500px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                hyphens: auto;
            }
        }

        img {
            padding: 0px 5px;

            &.--close {
                height: 10px;
                align-self: center;
                cursor: pointer;
            }

            &.--type {
                align-self: center;
                margin-top: 2px;
            }
        }
    }

    .--toast.show {
        animation: slide-in 0.2s ease-in-out;
    }

    .--toast.hide {
        animation: slide-out 0.2s ease-in-out;
    }
}

.toast-container[data-position="bottom-center"] {
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;

    @keyframes slide-in {
        from {
            transform: translateY(110%);
        }

        to {
            transform: translateY(0);
        }
    }

    @keyframes slide-out {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(110%);
        }
    }
}