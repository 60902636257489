@media(max-resolution:150dpi) {
    .tab_name_sec {
        left: 24.2%;
        top: 170px;
        position: absolute;
        color: #26890D;
    }
}

@media(max-resolution:100dpi) {
    .tab_name_sec {
        left: 21.32%;
        top: 25%;
        color: #26890D;
    }
}

.tabname {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.heading_num {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    width: 42px;
    height: 20px;
    padding-bottom: 30px;
}

.description {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    width: 280px;
    height: 20px;

}

@media(max-resolution:150dpi) {
    .step2_section {
        position: relative;
        top: 10px;
    }
}

@media(max-resolution:110dpi) {
    .step2_section {
        position: relative;
        top: 35px;
    }
}

@media(max-resolution:150dpi) {
    .allowed_name {
        position: relative;
        top: 12px;
        margin-bottom: 15px;
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #63666A;
    }
}

@media(max-resolution:110dpi) {
    .allowed_name {
        position: relative;
        top: 30px;
        margin-bottom: 15px;
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #63666A;
    }
}


@media(max-resolution:150dpi) {
    .upload-section {
        padding-top: 30px;
        width: 75%;
        right: 10px;
        top: 170px;
        position: fixed;
    }
}

@media(max-resolution:110dpi) {
    .upload-section {
        padding-top: 30px;
        width: 75%;
        right: 70px;
        top: 250px;
        position: fixed;
    }
}

.error-section {
    position: absolute;
    width: 107px;
    height: 24px;
    left: 800px;
    top: 140px;
    gap: 0px;
}

@media(max-resolution:150dpi) {
    .border_line {
        position: absolute;
        height: 82.2%;
        top: 92px;
        border-left: 1px solid #D0D0CE;
        left: 770px;
        z-index: 1;
    }
}

@media(max-resolution:110dpi) {
    .border_line {
        position: absolute;
        height: 84.1%;
        top: 103px;
        border: 1px solid #D0D0CE;
        left: 770px;
    }
}

.error_name {
    margin-bottom: 20px;
    opacity: 0.4;
    color: #26890D;
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

@media(max-resolution:150dpi) {
    .file-upload-box {
        position: relative;
        display: inline-block;
        max-width: 320px;
        border: 2px dotted #D0D0CE; // Style for the dashed border
        text-align: center;
        line-height: 200px; // Center text vertically
        width: 320px;
        height: 160px;
        top: 10px;
        cursor: pointer;
    }
}

@media(max-resolution:110dpi) {
    .file-upload-box {
        position: relative;
        display: inline-block;
        max-width: 320px;
        border: 2px dotted #D0D0CE; // Style for the dashed border
        text-align: center;
        line-height: 200px; // Center text vertically
        width: 320px;
        height: 180px;
        top: 30px;
        cursor: pointer;
    }
}

.file-upload-box input[type="file"] {
    position: absolute;
    margin-top: -20px;
    left: 0;
    width: 320px;
    height: 180px;
    opacity: 0; // Hide the default file input
    cursor: pointer !important;
}

@media(max-resolution:150dpi) {
    .file-upload-box label {
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: #53565A;
        display: block;
        bottom: 80%;
        position: relative;
        cursor: pointer;
    }
}

@media(max-resolution:110dpi) {
    .file-upload-box label {
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: #53565A;
        display: block;
        bottom: 65%;
        position: relative;
        cursor: pointer;
    }
}

.file-upload-box label span {
    display: block; // Place the span text on a new line
    cursor: pointer;

}

@media(max-resolution:150dpi) {
    .upload_img {
        width: 80px;
        height: 16px;
        bottom: 22%;
        position: relative;
        cursor: pointer;
    }
}

@media(max-resolution:110dpi) {
    .upload_img {
        width: 80px;
        height: 16px;
        bottom: 13%;
        position: relative;
        cursor: pointer;
    }
}

.upload-section a {
    color: black;
}


.upload-section a:hover {
    text-decoration: underline;
}


.upload-progress.success {
    background-color: #26890D; // Green color for success
}


.upload-section button {
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
}

.error-section ul {
    list-style-type: none;
    padding: 0;
}

.remove-file {
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 1px 0px 0px 0px;
    position: relative;

}

.file-container {
    display: flex;
}

.file-name {
    width: 275px;
    margin-left: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

@media(max-resolution:150dpi) {
    .file-info {
        top: -38px;
        position: relative;
    }
}

@media(max-resolution:110dpi) {
    .file-info {
        top: 50px;
        position: relative;
    }
}

.upload-progress-container {
    position: relative;
    margin-top: 10px;
}

.upload-progress {
    height: 5px;
    max-width: 320px;
    background: #26890D;

}

.upload-progress.error {
    background-color: #D32F2F; // Red color for errors
}

.progress-text {
    margin-top: 5px;
    font-size: 14px;
}

.upload-status {
    margin-top: 5px;
    width: 325px;


}

.upload-status.success {
    color: #26890D;
    ; // Green background for success
}

.upload-status.error {
    color: #DA291C;
    ; // Red background for errors
}


.file-size {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
}


@media(max-resolution:150dpi) {
    .bulk-user-btn {
        position: relative;
        width: 700px;
        right: 70px;
        top: 300px;
        display: flex;
        justify-content: start;
        margin-bottom: 13%;
        margin-left: 20%;

        .savebtn {
            width: 79px;
            margin: 0;
        }

    }
}

@media(max-resolution:110dpi) {
    .bulk-user-btn {
        position: relative;
        width: 700px;
        right: 32.5%;
        top: 300px;
        display: flex;
        justify-content: start;
        margin-top: 20%;

        .savebtn {
            width: 79px;
            margin: 0;
        }

    }
}

.error_message {
    display: inline-block;
}

.message_box {
    width: 602px;
    height: 16px;
    left: 22px;
    top: -20px;
    gap: 0px;
    opacity: 0.4 !important;
    position: relative;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.table-container {
    margin-top: 10px;
}

/* Table styling */
@media(max-resolution:150dpi) {
    .error-table {
        width: 620px;
        border-collapse: collapse;
        border: 1px solid #dcdcdc;
        position: relative;
    }
}

@media(max-resolution:110dpi) {
    .error-table {
        width: 980px;
        border-collapse: collapse;
        border: 1px solid #dcdcdc;
        position: relative;
    }
}

/* Table header styling */
.error-table thead {
    border: none;

}

.error-table th {
    padding: 15px;
    text-align: left;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;

}

.error-table td {
    border-bottom: 1px solid #dcdcdc;
    padding: 16px;
    height: 20px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;

}


.row_num {
    text-align: center;
    width: 13%;
}

.user_name {
    max-width: 140px;
    min-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

/* Error message column with more width */
.err_name {
    max-width: 390px;
    min-width: 390px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media(max-resolution:150dpi) {
    .detailed_err {
        width: 627px;
        max-height: 400px;
        overflow-y: auto;
        margin-top: 10px;

    }
}

@media(max-resolution:110dpi) {
    .detailed_err {
        width: 990px;
        max-height: 610px;
        overflow-y: auto;
        margin-top: 10px;

    }
}

.detailed_err::-webkit-scrollbar-thumb {
    background: #D0D0CE;
    width: 6px;

}

.PopUpModel.title {
    color: red
}

/* Style for tooltip */
.user_name,
.err_name {
    position: relative;
}

.user_name[title]:hover::after,
.err_name[title]:hover::after {
    content: attr(title);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease;
}

/* Make cursor pointer on hover */
.user_name[title],
.err_name[title] {
    cursor: pointer;
}