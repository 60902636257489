@use "../../theme";

.create-role-container {
    width: 70%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        color: theme.$base-color;
        width: 70%;
        margin-bottom: 2%;
        margin-top: 2%;
    }

    .create-role-div {
        display: flex;
        flex-direction: row;
        width: 70%;
        margin: 10px;
        justify-content: space-between;

        .role-txtbox {
            width: 70%;
        }

        label {
            display: block;
            margin-bottom: 10px;
            width: 20%;
        }

        @media(max-resolution:150dpi) {
            .Scope-radio {
                width: 62%;
                margin-right: 65px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                div {
                    width: 50%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .radio::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: theme.$white-color;
                        border: 2px solid theme.$border-color;
                    }
                    .radio:checked:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: theme.$white-color;
                        border: 2px solid theme.$base-color;
                    }
                    .radio {
                        content: '';
                        position: relative;
                        display: inline-block;
                        margin-right: 5px;
                        width: 14px;
                        height: 14px;
                        visibility: visible;
                        border: none;
                        appearance: none;
                        accent-color: theme.$white-color;
                    }

                    label {
                        width: 70%;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        @media(max-resolution:100dpi) {
            .Scope-radio {
                width: 60%;
                margin-right: 100px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                div {
                    width: 50%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .radio::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: theme.$white-color;
                        border: 2px solid theme.$border-color;
                    }

                    .radio:checked:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: theme.$white-color;
                        border: 2px solid theme.$base-color;
                    }

                    .radio {
                        content: '';
                        position: relative;
                        display: inline-block;
                        margin-right: 5px;
                        width: 14px;
                        height: 14px;
                        visibility: visible;
                        border: none;
                        appearance: none;
                        accent-color: theme.$white-color;
                    }

                    label {
                        width: 70%;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    span {
        width: 70%;
        color: theme.$subtext-color;
    }

    .create-role-featurs-div {
        display: flex;
        flex-direction: row;
        width: 70%;
        margin: 10px;
        height: 35%;
        justify-content: space-between;
        margin-top: 2%;


        label {
            display: block;
            margin-bottom: 10px;
            width: 20%;
        }

        .Scope-radio {
            width: 70%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            div {
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;

                label {
                    width: 70%;
                    margin-bottom: 0px;
                }
            }
        }

        .features-list {
            width: 70%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .features {
                display: flex;
                flex-direction: row;
                width: 44%;
                align-items: center;

                .container {
                    width: 70%;
                    display: block;
                    position: relative;
                    padding-left: 25px;
                    cursor: pointer;
                    font-size: 14px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    font-family: open sans;
                    margin-bottom: 10px;
                }

                /* Hide the browser's default checkbox */
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    height: 16px;
                    width: 16px;
                    background-color: white;
                    border: 1px solid black;
                }


                /* When the checkbox is checked, add a blue background */
                .container input:checked~.checkmark {
                    background-color: rgba(38, 137, 13, 1);
                    border: none;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked~.checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .container .checkmark:after {
                    left: 5px;
                    top: 2px;
                    width: 3px;
                    height: 7px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }

    @media(max-resolution:150dpi) {
        .create-user-btn {
            margin-right: 2px;
            width: 55%;
            display: flex;
            justify-content: end;
            margin-bottom: 4px;
        }
    }

    @media(max-resolution:100dpi) {
        .create-user-btn {
            width: 38%;
            margin-right: 2px;
            display: flex;
            justify-content: end;
        }
    }
}

@media(max-resolution:150dpi) {
    .create-role-container {
        height: 90%;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;

        h3 {
            width: 70%;
            color: theme.$base-color;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }
    }
}

@media(max-resolution:125dpi) {
    .create-role-container {
        width: 90%;
        height: 90%;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        h3 {
            width: 70%;
            color: theme.$base-color;
            margin-bottom: 3%;
            margin-top: 6%;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }
    }
}