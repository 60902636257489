@use "../../theme";

.popup-main {
    background: theme.$white-color;
    width: 25%;
    min-width: 400px;
    position: relative;
    height: 189px;

    // border-radius: 4px;

    .--header {
        display: flex;
        padding: 15px;
        height: 56px;
        //margin-left: 10px;

        img {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
            padding: 3px, 5px, 3px, 5px;
        }
    }

    .--contentbody {
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 53px;
        padding: 9px 24px 24px 24px;
        gap: 0px;
        opacity: 0.9px;





        .--formItem {
            margin: 10px;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            label {
                display: block;
                margin: 5px;
                //color: theme.$header-color;
            }

            input {
                border: 1px solid theme.$border-color;
                // border-radius: 4px;
                width: 100%;
                padding: 5px;
                outline: none !important;
            }

            .dropdown-main {
                width: 100%;
            }

        }
    }

    .--footer {
        display: flex;
        flex-direction: row-reverse;
        padding: 15px;
        align-items: center;

        .--popupbtn {
            width: 75px;
            height: 32px;
            padding: 3px 8px 3px 8px;
            justify-content: center;
            align-content: center;

        }


        .--testcancel {
            background: theme.$header-color;

        }
    }

    .--deleteBtn {
        background-color: theme.$error-color;
    }

}