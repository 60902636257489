@use "../../theme";

.edit-role-container {
    width: 70%;
    height: 80%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //margin-top: 80px;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        color: theme.$base-color;
        // margin-right: 50%;
        width: 70%;
        margin-bottom: 2%;
    }

    .edit-role-div {
        display: flex;
        flex-direction: row;
        width: 70%;
        margin: 10px;
        justify-content: space-between;

        .edit-role-txtbox {
            width: 70%;

            // display: flex;
            // justify-content: center;
            .--search-card {
                height: 40Px;

                width: 80%;
            }
        }

        label {
            display: block;
            margin-bottom: 10px;
            // color: theme.$header-color;
            width: 20%;
        }

        @media(max-resolution:150dpi) {
            .edit-Scope-radio {
                width: 61%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-right: 80px;

                div {
                    width: 50%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    input {
                        width: 10%;
                    }

                    label {
                        width: 70%;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        @media(max-resolution:100dpi) {
            .edit-Scope-radio {
                width: 61%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-right: 100px;

                div {
                    width: 50%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    input {
                        width: 10%;
                    }

                    label {
                        width: 70%;
                        margin-bottom: 0px;
                    }
                }
            }
        }


    }

    span {
        width: 70%;
        color: theme.$subtext-color;
    }

    .edit-role-features-div {
        display: flex;
        flex-direction: row;
        width: 70%;
        height: 20%;
        margin: 10px;
        justify-content: space-between;
        margin-top: 3%;

        label {
            display: block;
            margin-bottom: 10px;
            // color: theme.$header-color;
            width: 20%;
        }

        .edit-role-features-list {
            width: 70%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .edit-role-features {
                display: flex;
                flex-direction: row;
                width: 44%;
                height: 30%;
                align-items: center;

                .container {
                    width: 70%;
                    display: block;
                    position: relative;
                    padding-left: 25px;
                    cursor: pointer;
                    font-size: 14px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    font-family: open sans;
                    margin-bottom: 10px;
                }

                /* Hide the browser's default checkbox */
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 2px;
                    left: 0;
                    height: 16px;
                    width: 16px;
                    background-color: white;
                    border: 1px solid black;
                }

                /* When the checkbox is checked, add a blue background */
                .container input:checked~.checkmark {
                    background-color: rgba(38, 137, 13, 1);
                    border: none;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked~.checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .container .checkmark:after {
                    left: 5px;
                    top: 2px;
                    width: 3px;
                    height: 7px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
    }

}

@media(max-resolution:190dpi) {
    .edit-role-btn {
        margin-right: 17%;
        width: 70%;
        display: flex;
        justify-content: end;
        margin-top: 6%;

        .--savebtn {
            width: 15%;
        }
    }
}

@media(max-resolution:100dpi) {
    .edit-role-btn {
        margin-right: 35%;
        width: 70%;
        display: flex;
        justify-content: end;
        margin-top: 10%;

        .--savebtn {
            width: 15%;
        }
    }
}

@media(max-resolution:150dpi) {
    .edit-role-container {
        height: 90%;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }

        .create-user-div {
            width: 70%;
            // height: 10%;
            margin: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .create-user-txtbox {
                height: 10%;
                width: 60%;
            }
        }

    }
}

@media(max-resolution:125dpi) {
    .edit-role-container {
        width: 90%;
        height: 90%;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 3%;
            margin-top: 3%;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }

    }
}