
@use "../../theme";
.table-container {
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);


    .statusFilter{
        position: relative;
        
    }

    .scheduled--filter-dropdown{
        animation: fade-in-filter 0.3s ease-in-out;
        position: absolute;
        width: auto;
        background-color: theme.$white-color;
        border: 1px solid theme.$border-color;
        z-index:1000;  
        right:330px;
        top:270px;
        
    }

    .filterBox{
        padding: 12px;
    }

    .title{
        font-size:12px;
        font-weight: 600;
        margin-bottom: 8px;
        color: theme.$cool-gray;
    }

    .searchHolder{
        margin-bottom: 12px;
    }
    .searchBox{
        width:100%;
        padding  :6px 8px;
        border: 1px solid theme.$border-color;
        
    }

    .filterContent{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .filterContent label{
        display:flex;
        align-items: center;
        font-size: 14px;
        

        input[type="checkbox"] {
            margin-right: 10px;
            padding:0px 10px 5px 10px;
            cursor: pointer;
            accent-color: theme.$base-color;
        }

       
    }


    .filterFooter{
        display:flex;
        justify-content:flex-end;
        padding: 5px;
        
    }

    .apply-btn, .cancel-btn{
        padding: 6px 12px;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
    }

    .apply-btn{
        background-color: theme.$base-color;
        color:theme.$white-color;
        border: none;
    }
    .cancel-btn{
        background-color: transparent;
        color:theme.$base-color;
        border: none;
        
    }
    

    

    .table-heading {
        width: 90%;
        height: 8%;
        margin-top: 2%;
        display: flex;
        justify-content: space-between;


        .--search-card {
            height: 32px;
            width: 25%;
        }
    }

    .microservice-update-container {
        display: flex;
        width: 100%;
        justify-content: end;
        margin-bottom: 75px;
        margin-right: 100px;

        //justify-content: space-between;
    }



    h3 {
        width: 60%;
        height: 100%;
        margin-bottom: 20px;
        color: theme.$base-color;
        margin-left: 2px;
    }

    .underline {
        border-bottom: 1px solid theme.$border-color;
    }

    .apply-border-bottom{
        border-bottom: 1px solid theme.$border-color;
    }
    .table-header {
        width: 90%;
        height: 6%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      // border-bottom: 1px solid theme.$border-color;
        margin-top: 3%;
        color: theme.$header-color;

        .table-content {
            width: 20%;
            display: flex;
            align-items: center;
            //justify-content: center;
        }
    }

    .table-body {
        width: 90.7%;
        height: 70%;
        overflow-y: scroll;
        margin-left: .75%;
      
        .apply-border-top {
            border-top:1px solid theme.$border-color;
        }
        .table-content {
            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            border-bottom: 1px solid theme.$border-color;

            .table-data {
                width: 20%;
                height: 100%;
                display: flex;
                align-items: center;
                //justify-content: center;
                overflow: hidden;

            }

            .disable-edit {
                pointer-events: none;
                opacity: 0.3;
            }

            .--pass {
                color: theme.$base-color;
            }

            .--fail {
                color: theme.$error-color;
            }
        }

        .table-content:hover {
            background-color: theme.$border-color;
        }
    }
}