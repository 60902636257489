@use "../../theme";

.admin-home-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px);
    justify-content: center;
    align-items: center;

    h2 {
        color: theme.$base-color;
        margin-top: 60px;
        margin-bottom: 20px;

    }


    .admin-home-search {
        width: 40%;
        display: flex;
        justify-content: center;
        height: 6%;

        .--search-card {
            width: 100%;
            height: 100%;

            input {
                width: 100%
            }
        }
    }

    .admin-home-btns {
        height: 60%;
        width: 90%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 5%;

        .menu-items {
            width: 23.6%;
            height: 46%;
            border: 1px solid theme.$border-color;
            display: flex;
            //align-items: center;
            flex-direction: column;
            // margin-left: 5px;
            margin-bottom: 10px;
            justify-content: space-evenly;

            .admin-action-icon {
                width: 80%;
                height: 35%;
                display: flex;
                justify-content: end;

                .disabled{
                    color:theme.$disabled-color
                }

                h4 {
                    margin-top: 19px;
                    width: 131px;
                    //text-align: center;
                }
            }

            .admin-action {
                display: flex;
                flex-direction: row;
                height: 80%;
                width: 100%;
                overflow: hidden;
                justify-content: space-evenly;
                align-items: flex-start;
                
                .disabled{
                    opacity: 0.48;
                }


                img {
                    width: 25%;

                    
                }

                div {
                    width: 169px;
                    height: 120px;
                    line-height: 20px;
                    margin-top: 4px;
                    display: flex;
                    flex-direction: column;

                    label {
                        color: theme.$base-color;
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .selected-tile {
            border: 2.5px solid theme.$base-color;
        }
    }
}

@media (min-resolution:125dpi) {
    .admin-home-container {
        h2 {
            margin-top: 30px;
            margin-bottom: 10px;
            font-size: 25px;
            line-height: 35px;
        }

        .admin-home-btns {
            .menu-items {
                .admin-action-icon {

                    h4 {
                        font-size: 14px;
                        line-height: 12px;
                    }
                }

                .admin-action {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;

                    div {
                        .label-link {
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 17px;
                        }
                    }
                }
            }
        }
    }
}