@use "../../theme";

.SMTP-config-container {
    width: 90%;
    height: 90%;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        width: 70%;
        //height: 10%;
        color: theme.$base-color;
        margin-bottom: 25px;
    }

    .SMTP-config-div {
        width: 70%;
        // height: 10%;
        margin: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .create-user-txtbox {
            height: 10%;
            width: 60%;
        }

        label {
            width: 30%;
            display: block;
        }
    }

    .SMTP-config-btn {
        //margin-left: 20px;
        width: 70%;
        display: flex;
        justify-content: end;
        margin-top: 20%;

        .--resetbtn {
            background: theme.$white-color;
            border: 2px solid theme.$base-color;
            color: theme.$base-color;
        }
    
    }
}