@use "../../theme";

.toggle-container {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    cursor: pointer;

    &.toggle-on {
        flex-direction: row;
    }

    .toggle-track {
        border: 2px solid theme.$black-color;
        width: 10px;
        height: 4px;
        background: theme.$black-color;

        &.toggle-on {
            background: theme.$base-color;
            border-width: 2px;
            border-color: theme.$base-color;
        }
    }

    .toggle-thumb {
        border: 1px solid theme.$black-color;
        width: 16px;
        height: 16px;
        border-radius: 50%;


        &.toggle-on {
            border: 6px solid theme.$base-color;
        }
    }
}