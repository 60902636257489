@use "../../theme";

.search-box {
    width: 100%;
    display: flex;
    //justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 80%;

    .searchbox-main {
        width: 100%;
        background: theme.$white-color;
        border: 1px solid theme.$border-color;
        // border-radius: 4px;
        align-items: center;
        display: flex;

        input {
            width: 100%;
            outline: none;
            border: none;
        }

        img {
            margin: 0 10px;
            height: 12px;
        }
    }

    .options {
        border: 1px solid theme.$border-color;
        position: absolute;
        z-index: 100;
        width: 100%;
        top: 100%;
        overflow-y: scroll;
        background-color: theme.$white-color;
        height: 80px;

        div {
            height: 35px;
            text-align: start;
            display: flex;
            align-items: center;

            label {
                margin-left: 3%;
            }
        }

        div:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
    }
}