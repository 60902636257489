@use "../../theme";

.edit-project-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: theme.$white-color;
    box-shadow: 1.09375px 2.1875px 7.65625px 4.375px rgba(132, 129, 129, 0.25);

    h3 {
        width: 60%;
        color: theme.$base-color;
        margin-bottom: 20px;
        margin-top: 30px;

    }

    .edit-Project-div {
        display: flex;
        flex-direction: row;
        width: 60%;
        margin: 10px;
        justify-content: space-between;
        align-items: center;

        label {
            width: 30%;
            display: block;
            margin-bottom: 10px;
        }

        .edit-apptype-select {
            width: 100%;
            height: 100%;

            .--search-card {
                height: 100%;
                width: 80%;
            }
        }
    }

    .edit-select-app-div {
        width: 60%;
        display: flex;
        margin-bottom: 3%;

    }

    .edit-select-app {
        height: 20%;
        display: flex;
        width: 60%;
        justify-content: space-between;
        margin-bottom: 2%;

        .edit-apptype-select {
            width: 18%;
            display: flex;
            flex-direction: column;
            //justify-content: space-around;
            border: 1px solid theme.$border-color;
            align-items: center;
            cursor: not-allowed;

            .disable {
                opacity: 0.5;
            }

            div {
                width: 100%;
                margin-left: 10%;
            }

            img {
                width: 35%;
                height: 80%;
            }
        }

        .selectedApp {
            border: 3px solid theme.$base-color;
        }
    }

    .edit-project-btn {
        display: flex;
        width: 60%;
        align-items: flex-end;
        justify-content: end;
        margin-top: 5%;

        .--savebtn {
            width: 15%;
        }
    }
}

@media(max-resolution:150dpi) {
    .edit-project-container {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
        }


    }
}

@media(max-resolution:125dpi) {
    .edit-project-container {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        h3 {
            width: 70%;
            //height: 10%;
            color: theme.$base-color;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;

        }

    }
}